@tailwind base;

@layer base {
  html {
    @apply h-full touch-manipulation antialiased;
    /* Base font-size, make sure also set in .vscode/settings.json to get correct autocomplete suggestions */
    font-size: 15px;
  }
  body {
    @apply min-h-full bg-white text-base text-gray-700;
  }
  *,
  *:before,
  *:after {
    text-underline-position: under;
  }
  svg {
    @apply transform-gpu;
  }
}
