body .intercom-lightweight-app {
  @apply z-intercom;
}

.intercom-lightweight-app .intercom-lightweight-app-launcher {
  @apply bg-white;
  box-shadow:
    0 1px 6px 0 rgba(0, 0, 0, 0.1),
    0 2px 32px 0 rgba(0, 0, 0, 0.2);
  & svg path {
    @apply fill-current text-blue-200;
  }
}

html.preview-player-showing
  .intercom-lightweight-app
  .intercom-lightweight-app-launcher,
html.preview-player-showing
  .intercom-namespace
  .intercom-app
  .intercom-launcher-frame {
  @apply hidden;
}
